<template>
  <div class="about">
    <div class="head">
      <img :src="aboutLists.banner" />
    </div>
    <div class="main w">
      <h1>公司简介</h1>
      <div class="content">
        <h1>成</h1>
        <div class="right-content">
          <div class="section">
            都恒速科技有限公司成立于2015年4月，在成都、重庆设有办公室，已服务200余家企业，目前已拥有软件著作权40余项实用新型专利20余项IS09001认证软件企业认证国家高新技术企业认证。
          </div>
          <div class="section">
            拥有完善的WEB、微信、APP应用开发核心技术团队，高级项目经理，产品经理、产品测试人员，UI设计师，程序开发工程师若干。我们的产品不仅得到了客户的高度认可，还同时获得了高新区政府、高新区技术创新服务中心的肯定。
          </div>
        </div>
      </div>

      <div class="nums">
        <div class="num" v-for="item in numList" :key="item.id">
          <div class="num-top">
            <span class="top_num">{{ item.num }}</span>
            <div class="top-right_box">
              <span>+</span>
              <span>{{ item.unit }}</span>
            </div>
          </div>
          <div class="num-desc">{{ item.desc }}</div>
        </div>
      </div>

      <div class="bg">
        <img src="@/assets/imgs/about/bg_c.png" alt="" />
      </div>
    </div>

    <div class="duty">
      <div class="w text">
        <h1>我们的愿景和使命</h1>
        <div class="content">
          <div class="desc">
            我们逐渐成长为一支技术完备、人尽其用、创新无限的科技型创新团队。我们在发展中遵循着不断进步、不断突破、不断超越自我的核心价值观。坚持执行“将产品质量放在第一位”的经营理念，将客户的要求作为我们的最高要求，用专业、实力和创意打动了众多企业。
          </div>
          <div class="desc">
            我们将继续承载梦想，不负众望，以质量为核心、以客户为导向，为您的企业保驾护航。
          </div>
        </div>
      </div>
    </div>

    <div class="w contact">
      <h1>联系我们</h1>

      <div class="contact-cards">
        <div class="contact-card">
          <h2>产品咨询</h2>
          <p>产品演示、产品报价</p>
          <div class="desc">
            <span>联系方式：028-6573-2736</span>
            <span>zhangzhiwei@cdhengsu.com</span>
          </div>
          <button class="btn">联系我们</button>
        </div>
        <div class="contact-card">
          <h2>市场合作</h2>
          <p>市场合作、媒体推广</p>
          <div class="desc">
            <span>zhangzhiwei@cdhengsu.com</span>
          </div>
          <button class="btn" @click="handleContact">联系我们</button>
        </div>
      </div>
    </div>

    <contact-dialog ref="contactRef" />
  </div>
</template>

<script>
import contactDialog from '@/components/contactDialog.vue'
import { getBnners } from '../../api/banner.js'
import { aboutUs } from '../../api/about.js'
export default {
  data() {
    return {
      aboutLists: {},
      companyDesc: [],
      bgPic: require('../../assets/imgs/about/office.png'),
      // banner: [],
      numList: [
        { id: 1, desc: '服务客服', num: 200, unit: '家' },
        { id: 2, desc: '技术团队', num: 40, unit: '人' },
        { id: 3, desc: '专利认证', num: 60, unit: '个' }
      ]
    }
  },
  components: { contactDialog },
  computed: {},
  created() {
    this.initData()

    window.addEventListener('scroll', this.listenPageScroll)
  },
  methods: {
    listenPageScroll(e) {
      console.log('e', e)
    },
    initData() {
      aboutUs()
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data)
            this.aboutLists = res.data ? res.data : {}
            this.companyDesc = res.data.companyIntroduction.split('\n')
          }
        })
        .catch((error) => {
          console.log('error', error)
        })

      getBnners({ classifyId: -3 }).then((res) => {
        this.banner = res.data
      })
    },
    handleContact() {
      this.$refs.contactRef.handleOpen()
    }
  }
}
</script>

<style lang="less" scoped>
.about {
  .head {
    width: 100%;
    height: 650px;
    background-attachment: fixed;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .main {
    background-color: #fff;
    height: 800px;
    padding: 72px 0;
    box-sizing: border-box;
    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: #2a2f32;
    }

    .content {
      margin: 40px 0 48px;
      display: flex;
      h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 36px;
        color: #2c4252;
        margin-right: 16px;
      }
      .right-content {
        .section {
          text-align: left;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 32px;
          color: #2c4252;
          transform: matrix(1, 0, 0, 1, 0, 0);
          &:nth-child(1) {
            margin-bottom: 40px;
          }
        }
      }
    }

    .nums {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .num {
        margin: 30px 0;
        .num-top {
          display: flex;
          .top_num {
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 36px;
            text-align: center;
            background: linear-gradient(
              234.47deg,
              #2473e9 29.17%,
              #27defd 95.14%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
          .top-right_box {
            align-self: flex-end;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            color: #2473e9;
          }
        }
        .num-desc {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 36px;
          text-align: center;
          color: #20445f;
        }
      }

      .bg {
        width: 100%;
        height: 186px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .duty {
    width: 100%;
    height: 560px;
    background: url('../../assets/imgs/about/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    .text {
      text-align: left;
      h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        color: #2a2f32;
        margin-bottom: 48px;
      }
      .content {
        .desc {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 36px;
          color: #2c4252;
          &:nth-child(1) {
            margin-bottom: 48px;
          }
        }
      }
    }
  }

  .contact {
    height: 581px;
    padding: 72px 0;
    box-sizing: border-box;

    h1 {
      margin-bottom: 62px;
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: #2a2f32;
    }
    .contact-cards {
      display: flex;
      align-items: center;
      justify-content: center;
      .contact-card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 300px;
        height: 300px;
        background: linear-gradient(195.53deg, #24aee9 25.8%, #2473e9 80.49%);
        border-radius: 16px;
        &:nth-child(1) {
          margin-right: 80px;
        }
        h2 {
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 36px;
          text-align: center;
          color: #ffffff;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 36px;
          text-align: center;
          color: #ffffff;
        }
        .desc {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #ffffff;
          margin: 20px 0;
          letter-spacing: 1px;
        }
        .btn {
          width: 126px;
          height: 40px;
          background: #ffffff;
          border-radius: 25px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          text-align: center;
          color: #2473e9;
          border: 0;
        }
      }
    }
  }
}
</style>
