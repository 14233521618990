import { get, post } from './index'

// 添加留言
export const addMessage = (p) => {
  return post('/api/contactUs/addMessage', p)
}

// 获取留言设置详情
export const getContactInfo = (p) => {
  return get('/api/contactUs/getMessageSettingsDetails', p)
}
