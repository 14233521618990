<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="50%"
    :before-close="handleClose"
  >
    <div class="contact">
      <div class="heads">
        <p class="title">联系我们</p>
        <p class="subtitle">收到您填写的信息后，我们会通过手机或邮件与您联系</p>
      </div>
      <div class="contents">
        <el-form :model="messageLists" ref="messageRef" :rules="messageRules">
          <div class="mobile">
            <el-form-item prop="email">
              <el-input
                v-model="messageLists.email"
                placeholder="请输入您的邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item prop="phone">
              <el-input
                v-model="messageLists.phone"
                placeholder="请输入您的联系方式"
              ></el-input>
            </el-form-item>
          </div>

          <el-col>
            <el-form-item prop="remarks">
              <el-input
                resize="none"
                :autosize="{ minRows: 4, maxRows: 8 }"
                v-model="messageLists.remarks"
                placeholder="留下您的需求，获取免费解决方案或报价信息（选填）"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form>
        <el-button type="primary" class="btn" @click="handleSend('messageRef')"
          >发送</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { addMessage } from '../api/contact'
export default {
  data() {
    var checkEmail = (rule, value, callback) => {
      const regEmail =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      if (regEmail.test(value)) {
        return callback()
      }
      callback(new Error('请输入合格的邮箱'))
    }
    var checkMobile = (rule, value, callback) => {
      const regMobile =
        /^(0|86|17951)?(13[0-9]|15[012356789]|17[3678]|18[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        return callback()
      }
      callback(new Error('请输入合格的手机号'))
    }

    return {
      dialogVisible: false,
      messageLists: {
        remarks: '',
        email: '',
        phone: ''
      },
      messageRules: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
          // { validator: checkEmail, trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
          // {
          //   validator: checkMobile,
          //   trigger: 'blur'
          // }
        ]
      }
    }
  },
  methods: {
    handleOpen() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.messageRef.resetFields()
    },
    handleSend(formName) {
      let message = JSON.parse(JSON.stringify(this.messageLists))
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addMessage(message).then(() => {
            this.$refs[formName].resetFields()
            this.$message({
              type: 'success',
              message: '添加留言成功',
              duration: '700'
            })
            this.dialogVisible = false
          })
        } else {
          this.$message({
            type: 'info',
            message: '请填写完整信息',
            duration: '700'
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.contact {
  width: 100%;
  height: 360px;
  padding: 20px 0;
  background-color: #f5f9fc;
  box-sizing: border-box;
  .heads {
    margin-bottom: 36px;
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: #2a2f32;
      margin-bottom: 8px;
    }
    .subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 36px;
      text-align: center;
      color: #586167;
    }
  }

  .contents {
    width: 800px;
    margin: 0 auto;
    .mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-form-item {
        width: 48%;
      }
    }
  }
}

/deep/.el-dialog {
  background-color: #f5f9fc;
}
</style>
